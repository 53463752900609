const member = [
  {
    path: "/member/classify",
    name: "memberClassify",
    component: () => import("@/view/member/classify"),
  },
  {
    path: "/member/manage",
    name: "commonManage",
    component: () => import("@/view/member/manage"),
  },
  {
    path: "/member/manage/details",
    name: "commonDetails",
    component: () => import("@/view/member/manage/details"),
  },
  {
    path: "/member/badge",
    name: "memberBadge",
    component: () => import("@/view/member/badge"),
  },
  {
    path: "/member/examine",
    name: "memberExamine",
    component: () => import("@/view/member/examine"),
  },
  {
    path: "/member/business",
    name: "commonBusiness",
    component: () => import("@/view/member/business"),
  },
];
export default member;
