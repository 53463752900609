/** 必填 */
export const requiredRule = [{ required: true }];

/** 数字提示 */
export const numMessageRule = [{ required: true, message: "请输入数字" }];

/** 不能有空格 */
export const noSpaceRule = [
  { pattern: /(^[^\s]*)([^\s]*$)/, message: "不能有空格" },
];

/** 输入英文 */
export const enterEnglish = [{ pattern: /^[a-zA-Z]+$/, message: "请输入英文" }];

// 首尾不能有空格
export const notSpaceRule = [
  { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: "首尾不能有空格" },
];

// 汉字提示
export const charactersRule = [
  {
    pattern:
      /^[\u4E00-\u9FA5\uf900-\ufa2d]{1,10}[\.]{0,1}[\u4E00-\u9FA5\uf900-\ufa2d]{1,10}$/,
    message: "请输入2-10位汉字",
  },
];

/** 是否是手机号 */
export const mobilePhoneRule = [
  { pattern: /^1\d{10}/, message: "请输入正确的手机号" },
];

/** 是否是邮箱 */
export const isEmail = (email = "") =>
  /^([\.a-zA-Z0-9_-])+@[\.a-zA-Z0-9_-]+(\.[\.a-zA-Z0-9_-]+)+$/.test(email);

/** 请勿输入中英文 */
export const enterChineseEnglish = [
  { pattern: /^[^\u4e00-\u9fa5A-Za-z]+$/, message: "请勿输入中英文" },
];

/** 是否是数字英文组合 */
export const enterNumberEnglish = [
  { pattern: /^[A-Za-z0-9]+$/, message: "请输入英文数字组合" },
];

/** 邮箱格式 */
export const emailRegex = [
  {
    pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    message: "邮箱格式错误",
  },
];

/** 是否是电话 / 座机 等 */
export const telephoneRule = [
  {
    pattern:
      /^(0\d{2,3}-\d{7,8})$|^(1[3456789]\d{9})$|^(400-\d{3}-\d{4})$|^(\d{7,8})$/,
    message: "请输入正确的电话",
  },
];

/** 密码6-20位 */
export const passwordRule = [
  {
    pattern: /^.{6,20}$/,
    message: "6-20位",
  },
];
