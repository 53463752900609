const info = [
  {
    path: "/info/productClassify",
    name: "infoProductClassify",
    component: () => import("@/view/info/productClassify"),
  },
  {
    path: "/info/product",
    name: "infoProduct",
    component: () => import("@/view/info/product"),
  },
  {
    path: "/info/product/create",
    name: "infoProductCreate",
    component: () => import("@/view/info/product/create"),
  },
  {
    path: "/info/solution",
    name: "infoSolution",
    component: () => import("@/view/info/solution"),
  },
  {
    path: "/info/solution/create",
    name: "infoSolutionCreate",
    component: () => import("@/view/info/solution/create"),
  },
  {
    path: "/info/marketClassify",
    name: "infoMarketClassify",
    component: () => import("@/view/info/marketClassify"),
  },
  {
    path: "/info/market",
    name: "infoMarket",
    component: () => import("@/view/info/market"),
  },
  {
    path: "/info/market/create",
    name: "infoMarketCreate",
    component: () => import("@/view/info/market/create"),
  },
  {
    path: "/info/softwareClassify",
    name: "infoSoftwareClassify",
    component: () => import("@/view/info/softwareClassify"),
  },
  {
    path: "/info/software",
    name: "infoSoftware",
    component: () => import("@/view/info/software"),
  },
  {
    path: "/info/software/create",
    name: "infoSoftwareCreate",
    component: () => import("@/view/info/software/create"),
  },
];
export default info;
