<template>
  <div class="mainPage">
    <div class="login">
      <div class="login-left"></div>
      <div class="login-right">
        <div class="login-right-title">{{ $title }}</div>
        <div class="form-submit">
          <el-form
            class="login-form"
            status-icon
            :rules="loginRules"
            ref="loginForm"
            :model="loginForm"
            label-width="0"
          >
            <el-form-item prop="username">
              <el-input
                maxlength="20"
                size="small"
                @keyup.enter.native="handleLogin"
                v-model="loginForm.username"
                auto-complete="off"
                placeholder="请输入用户名"
              >
                <i slot="prefix" class="iconfont icon-user1" />
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                maxlength="20"
                size="small"
                @keyup.enter.native="handleLogin"
                :type="passwordType"
                v-model="loginForm.password"
                auto-complete="off"
                placeholder="请输入密码"
              >
                <i
                  class="el-icon-view el-input__icon"
                  slot="suffix"
                  @click="showPassword"
                />
                <i slot="prefix" class="iconfont icon-password" />
              </el-input>
            </el-form-item>
            <el-form-item
              prop="code"
              style="display: flex;align-items: center;"
            >
              <div style="display: flex;">
                <el-input
                  maxlength="4"
                  size="small"
                  @keyup.enter.native="handleLogin"
                  v-model="loginForm.code"
                  auto-complete="off"
                  placeholder="请输入验证码"
                  style="width: 200px;"
                >
                  <i slot="prefix" class="el-icon-key" size="16"></i>
                </el-input>
                <span class="img_box" @click="getCodeHandle()">
                  <img
                    :src="captchaImg"
                    alt=""
                    style="width: 100%;height: 100%;"
                    v-if="captchaImg"
                  />
                </span>
              </div>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                size="small"
                :loading="loading"
                @click.native.prevent="handleLogin"
                class="login-submit"
                >登录
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, userinfo, webCaptcha } from "../../api";
import { mapActions } from "vuex";
export default {
  name: "login",
  data() {
    return {
      screenWidth: "",
      divWidth: "",
      loginForm: {
        username: "",
        password: "",
        uuid: "",
        code: "",
      },
      loginRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 1, message: "密码长度最少为6位", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      passwordType: "password",
      loading: false,
      captchaImg: "",
    };
  },
  async created() {
    this.screenWidth = document.body.clientWidth;
    if (document.body.clientWidth <= 1000) {
      this.divWidth = document.body.clientWidth * 1 - 40;
    }
    this.loginForm.uuid = await this.guid();
    this.getCodeHandle();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        this.divWidth = document.body.clientWidth * 1 - 40;
      })();
    };
    // this.allButton('empty')
  },
  methods: {
    ...mapActions(["allButton"]),
    // 生成uuid
    guid() {
      var chars = "0123456789abcdef";
      var uuid = "";
      for (var i = 0; i < 32; i++) {
        var index = Math.floor(Math.random() * 16);
        uuid += chars[index];
      }
      return uuid;
    },
    // 刷新验证码
    getCodeHandle() {
      webCaptcha({ uuid: this.loginForm.uuid }).then(async (res) => {
        // return;
        const blob = new Blob([res]); // 获取 Blob 对象
        const reader = new window.FileReader();

        reader.onloadend = () => {
          const data = reader.result;
          this.captchaImg = data;
          console.log(this.captchaImg);
        };
        reader.readAsDataURL(blob);
      });
    },
    convertBlobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.captcha = reader.result; // Base64 数据
        console.log(this.captcha);
      };
    },
    showPassword() {
      this.passwordType === ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          // const loading = this.$loading({
          //     lock: true,
          //     text: '登录中,请稍后。。。',
          //     spinner: "el-icon-loading"
          // });
          login({
            // username: this.loginForm.username,
            // password: this.loginForm.password,
            ...this.loginForm,
          })
            .then((res) => {
              // 获取数据成功后的其他操作
              if (res.code == 200) {
                this.$router.push("/");
                this.$store.commit("setToken", res.data.token);
                // loading.close();
              }
              if (res.code == 400) {
                this.getCodeHandle();
              }
              this.loading = false;
              //  else {
              //   this.$message.error(res.msg);
              //   // loading.close();
              // }
            })
            .catch((err) => {
              // loading.close();
              // this.$message.error(err.msg);
              // this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.mainPage {
  width: 100%;
  height: 100vh;
  background: #049ec4;
  background-image: url("../../style/image/loginBg.jpg");
  display: flex;
  align-items: center;
}
.login {
  width: 100%;
  min-width: 1500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-left {
  width: 500px;
  background-image: url("../../style/image/loginLeft.png");
  height: 500px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-left: 15%;
}
.login-right {
  width: 450px;
  height: auto;
  background-color: #ffffff;
  border-radius: 20px;
  margin-right: 15%;
  padding: 50px 0;
}
.login-right-title {
  color: #333;
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.form-submit {
  width: 325px;
  margin: 0 auto;
}
.form-submit >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}
.login-submit {
  width: 100%;
  height: 45px;
  border: 1px solid #409eff;
  background: none;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 300;
  color: #409eff;
  cursor: pointer;
  margin-top: 30px;
  font-family: "neo";
  transition: 0.25s;
}
.img_box {
  display: inline-block;
  width: 100px;
  height: 32px;
  margin-left: 10px;
  line-height: 1;
  position: relative;
  cursor: pointer;
}
.img_tip {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(120%);
  font-size: 10px;
  color: rgb(244, 93, 93);
  width: 100px;
  text-align: center;
}
/deep/.el-icon-key:before {
  content: "\e6e2";
  font-size: 16px;
}
</style>
