<template>
  <el-dialog
    :title="choiceId ? '修改管理员' : '新增管理员'"
    :visible.sync="dialogVisible"
    width="60%"
    v-dialogdrag
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <avue-form
      :option="option"
      v-model="form"
      ref="form"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-before="uploadBefore"
      :upload-after="uploadAfter"
      @submit="formSubmit"
    >
      <template slot="role">
        <el-select v-model="form.role" multiple placeholder="请选择">
          <el-option
            v-for="item in role"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :disabled="item.state == 2 ? true : false"
          >
          </el-option>
        </el-select>
      </template>
      <template slot="headPortrait">
        <FormImgUpload
          :url="form.headPortrait"
          @upload="upload"
          :disabled="false"
          :limit="1"
          :maxSize="20"
          :accept="'.png,.jpg'"
          :multiple="true"
        />
        <div style="color: #c0c1c3">
          建议尺寸：60px*60px，图片格式：.png，.jpg
        </div>
        <!-- <FormImgUpload
          :url="form.headPortrait"
          @upload="upload"
          :disabled='true'
          :limit="1"
          :maxSize="5"
          :accept="'image/png,image/jpg'"
          :multiple="true"
        /> -->
      </template>
      <template slot="menuForm">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="submit(form)">确定</el-button>
      </template>
    </avue-form>
  </el-dialog>
</template>

<script>
import { actionUrl, imageUrl } from "@/config/public";
import { userAdd, userUpdate, userView } from "@/api/system/index";
import { emailRegex } from "@/utils/formRules";
import FormImgUpload from "@/components/formComponents/formImgUpload";

export default {
  name: "add",
  props: {
    dialogVisible: Boolean,
    choiceId: String,
  },
  components: {
    FormImgUpload,
  },
  data() {
    return {
      form: {
        headPortrait: [],
      },
      role: this.$store.state.system.role,
      parent: [], //归属上级
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "管理员账号",
            prop: "username",
            maxlength: 20,
            // showWordLimit: true,
            placeholder: "请输入",
            row: true,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "管理员密码",
            prop: "password",
            maxlength: 20,
            type: "password",
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入管理员密码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "确认密码",
            type: "password",
            prop: "confrimPassword",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入确认密码",
                trigger: "blur",
              },
              {
                validator: (_r, value) => {
                  const { password } = this.form;
                  if (password) {
                    const isValid = !!value && value === password;
                    return isValid
                      ? Promise.resolve()
                      : Promise.reject(new Error("密码不一致"));
                  }
                },
              },
            ],
          },
          {
            label: "管理员姓名",
            prop: "fullName",
            maxlength: 20,
            row: true,
            // showWordLimit: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入管理员姓名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "职位",
            prop: "post",
            maxlength: 20,
            row: true,
            // showWordLimit: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入职位",
                trigger: "blur",
              },
            ],
          },
          {
            label: "头像",
            row: true,
            type: "text",
            prop: "headPortrait",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传头像",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "管理员邮箱",
            prop: "email",
            maxlength: 100,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入管理员邮箱",
                trigger: "blur",
              },
              ...emailRegex,
            ],
          },
          {
            label: "管理员角色",
            prop: "roleList",
            type: "select",
            dicData: this.$store.state.system.role,
            row: true,
            multiple: true,
            placeholder: "请选择管理员角色",
            props: {
              label: "name",
              value: "id",
            },
          },
          {
            label: "状态",
            prop: "state",
            type: "radio",
            row: true,
            dicData: [
              { label: "启用", value: 1 },
              { label: "禁用", value: 2 },
            ],
            rules: [
              {
                required: true,
                message: "请选择状态",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    if (this.choiceId) {
      userView(this.choiceId).then((res) => {
        this.form = {
          ...res.data,
          headPortrait: [res.data.headPortrait],
          parentId: res.data.parentId == 0 ? "" : res.data.parentId,
        };
        // console.log(res.data)
      });
    }
  },
  methods: {
    uploadDelete() {
      // return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column);
    },
    uploadAfter(res, done) {
      done();
      // this.$message.success('上传后的方法')'
      // console.log(res);
      // this.form.headPortrait = res.image;
      // this.$nextTick(() => {
      //   this.form.headPortrait = res.path;
      // });
    },
    handleClose() {
      // this.$confirm("确认关闭？").then((res) => {
      this.$root.eventHub.$emit("changeAdd", false);
      // });
    },
    formSubmit(form, done) {},
    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          let that = this;

          if (this.choiceId) {
            userUpdate({
              id: this.choiceId,
              email: form.email,
              fullName: form.fullName,
              headPortrait: form.headPortrait[0],
              username: form.username,
              password: form.password,
              phone: form.phone,
              state: form.state,
              post: form.post,
              roleList: form.roleList.toString(),
              parentId: form.parentId || "0",
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                // console.log(res);
                if (res.code === 200) {
                  this.$message.success("修改成功");
                  this.$root.eventHub.$emit("changeAdd", true);
                  // done();
                }
              })
              .catch((err) => {
                that.$message.error(err.msg);
                // done();
              });
          } else {
            userAdd({
              email: form.email,
              fullName: form.fullName,
              post: form.post,
              headPortrait: form.headPortrait[0],
              username: form.username,
              password: form.password,
              phone: form.phone,
              state: form.state,
              roleList: form.roleList.toString(),
              parentId: form.parentId || "0",
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                // console.log(res);
                if (res.code === 200) {
                  that.$message.success(res.msg);
                  this.$root.eventHub.$emit("changeAdd", true);
                  // done();
                }
              })
              .catch((err) => {
                that.$message.error(err.msg);
                // done();
              });
          }
          this.form.type = 1;
          // done()
        }
      });
    },
    // 关闭
    handleClose() {
      this.$root.eventHub.$emit("changeAdd", true);
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.headPortrait = [...e];
      } else {
        this.form.headPortrait = [...this.form.headPortrait, e];
      }
    },
  },
};
</script>

<style scoped>
/deep/ .el-dialog__wrapper {
  z-index: 2003 !important;
}
</style>
