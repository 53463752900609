const equity = [
  {
    path: "/equity/classify",
    name: "equityClassify",
    component: () => import("@/view/equity/classify"),
  },
  {
    path: "/equity/manage",
    name: "equityManage",
    component: () => import("@/view/equity/manage"),
  },
  {
    path: "/equity/manage/create",
    name: "equityManageCreate",
    component: () => import("@/view/equity/manage/create"),
  },
];
export default equity;
