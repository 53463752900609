import { systemUploadUpdate, systemUploadView } from "@/api/system/upload";
const user = {
  state: {
    userInfo: {},
    Token: "",
    uploadView: {},
  },
  mutations: {
    setToken(state, info) {
      state.Token = info;
      // state.Token = 'info'
    },
    setUserInfo(state, info) {
      state.userInfo = info;
    },
    async setUploadView(state, info) {
      state.uploadView = info;
    },
  },
  actions: {
    async fetchUploadView({ commit }) {
      try {
        const res = await systemUploadView(); // 调用接口
        if (res.code === 200) {
          commit("setUploadView", res.data); // 更新状态
        }
      } catch (error) {
        console.error("Error fetching upload view:", error);
      }
    },
  },
};
export default user;
