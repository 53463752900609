const video = [
  {
    path: "/video/classify",
    name: "videoClassify",
    component: () => import("@/view/video/classify"),
  },
  {
    path: "/video/manage",
    name: "videoManage",
    component: () => import("@/view/video/manage"),
  },
  {
    path: "/video/manage/create",
    name: "videoManageCreate",
    component: () => import("@/view/video/manage/create"),
  },
];
export default video;
