const content = [
  {
    path: "/content/classify",
    name: "contentClassify",
    component: () => import("@/view/content/classify"),
  },
  {
    path: "/content/manage",
    name: "contentManage",
    component: () => import("@/view/content/manage"),
  },
  {
    path: "/content/manage/create",
    name: "contentManageCreate",
    component: () => import("@/view/content/manage/create"),
  },
];
export default content;
