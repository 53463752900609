import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

// pages
// 登录
import login from "@/pages/login/login";
// 首页
import index from "@/pages/index/index";
import errorPage from "@/view/errorPage/index"; //403页面

// view 内容
// index首页
import indexHome from "@/view/index/index";

// system 系统管理
import systemUser from "@/view/system/user";
import systemRole from "@/view/system/role";
import systemMenu from "@/view/system/menu/list";
import member from "./view/member"; // 会员管理
import equity from "./view/equity"; //权益管理
import info from "./view/info"; //资料管理
import video from "./view/video"; //培训学院
import qa from "./view/qa"; //qa管理
import content from "./view/content"; //内容管理
import join from "./view/join"; //内容管理
import navigation from "./view/navigation"; //导航管理
import factory from "./view/factory"; //盘厂列表
import banner from "./view/banner"; //banner管理
import news from "./view/news"; //新闻中心

import system from "./view/system"; //系统管理

export default new Router({
  // mode: 'history',//去掉#号、
  mode: "hash", //保留#号、
  routes: [
    {
      path: "/login",
      name: "login",
      component: login,
    },
    {
      path: "/",
      component: index,
      children: [
        // ...indexHome,
        {
          path: "/",
          name: "indexHome",
          component: indexHome,
        },
        {
          path: "/password",
          name: "password",
          component: () => import("@/pages/password/index"),
        },
        {
          path: "/info",
          name: "info",
          component: () => import("@/pages/info/index"),
        },
        {
          path: "/errorPage",
          name: "errorPage",
          component: errorPage,
        },
        // ------------------------------------------------------ 系统管理
        // {
        //   path: "/system/user",
        //   name: "systemUser",
        //   component: systemUser,
        // },
        // {
        //   path: "/system/role",
        //   name: "role",
        //   component: systemRole,
        // },
        ...info,
        ...equity,
        ...banner,
        ...member,
        ...video,
        ...qa,
        ...content,
        ...join,
        ...navigation,
        ...factory,
        ...system,
        ...news,
        {
          path: "/system/menu",
          name: "systemMenu",
          component: systemMenu,
        },
      ],
    },
  ],
});
