/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from "axios";
import QS from "qs";
import store from "../store/index";
import router from "../router";
import global from "../utils/global";

import { baseUrl } from "../config/public";
import { Message } from "element-ui";

// 环境的切换
// if (process.env.NODE_ENV == 'development') {
//     axios.defaults.baseURL = '/api';
// } else if (process.env.NODE_ENV == 'debug') {
//     axios.defaults.baseURL = '';
// } else if (process.env.NODE_ENV == 'production') {
//     axios.defaults.baseURL = 'http://api.123dailu.com/';
// }
axios.defaults.baseURL = baseUrl;

// 请求超时时间
axios.defaults.timeout = 10000;

// post请求头
axios.defaults.headers.post["Content-Type"] = "application/json";

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // let token = '';
    // if(window.sessionStorage.getItem("store")){
    //     let store = JSON.parse(window.sessionStorage.getItem("store"));
    //     console.log(store.user.Token)
    //     token = store.user.Token;
    // }else{
    //     token = store.state.user.Token;
    // }
    const token = store.state.user.Token;
    token && (config.headers.Authorization = token);
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      // console.log('response',response)
      // console.log('response',response.data.code)
      switch (response.data.code) {
        case 401:
          // console.log(response.data.msg)
          router.replace({
            path: "/login",
            replace: true,
            // query: { redirect: router.currentRoute.fullPath }
          });
          break;
        case 400:
          // console.log(response.data.msg)
          Message.error(response.data.msg);
          break;
        default:
        // console.log(response.data.msg)
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    // console.log('error',error.response.status)
    // console.log('error',error.response)
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          global.Set_store();
          router.replace({
            path: "/login",
            replace: true,
          });
          break;
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          console.log("登录过期，请重新登录1111");
          console.log("111");
          setTimeout(() => {
            console.log("111");
            router.push("/errorPage");
            // location.href = '/#/errorPage';
          }, 1000);
          // this.$message.error(error.response.data.msg);
          // 清除token
          // localStorage.removeItem('token');
          // store.commit('setToken', '');
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          break;
        // 404请求不存在
        case 404:
          // console.log('网络请求不存在')
          Message.error(error.response.data.msg);
          break;
        case 502:
          // console.log('网络请求不存在')
          Message.error("服务重启中");
          break;
        // 404请求不存在
        case 510:
          console.log(error.response);
          Message.error(error.response.data.msg);
          // this.$message.error('服务器异常');
          break;
        // 其他错误，直接抛出错误提示
        default:
          console.log(error.response, "error.response");

          Message.error(error.response.data.msg);
      }
      return Promise.reject(error.response);
    }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  if (url == "/web/captcha") {
    axios.defaults.responseType = "blob";
  } else {
    axios.defaults.responseType = "";
  }
  console.log(url, "urlurl");

  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.parse(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, QS.parse(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
/**
 * delete方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function Delete(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, QS.parse(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
