const qa = [
  {
    path: "/qa/manage",
    name: "qaManage",
    component: () => import("@/view/qa/manage"),
  },
  {
    path: "/qa/manage/create",
    name: "qaManageCreate",
    component: () => import("@/view/qa/manage/create"),
  },
];
export default qa;
