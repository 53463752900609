const news = [
  {
    path: "/news/video",
    name: "newsVideo",
    component: () => import("@/view/news/video"),
  },
  {
    path: "/news/info",
    name: "newsInfo",
    component: () => import("@/view/news/info"),
  },
  {
    path: "/news/info/create",
    name: "newsInfoCreate",
    component: () => import("@/view/news/info/create"),
  },
  {
    path: "/news/notice",
    name: "newsNotice",
    component: () => import("@/view/news/notice"),
  },
  {
    path: "/news/notice/create",
    name: "newsNoticeCreate",
    component: () => import("@/view/news/notice/create"),
  },
];
export default news;
