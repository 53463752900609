const system = [
  {
    path: "/system/user",
    name: "systemUser",
    component: () => import("@/view/system/user"),
  },
  {
    path: "/system/role",
    name: "systemRole",
    component: () => import("@/view/system/role"),
  },
  {
    path: "/system/register",
    name: "systemRegister",
    component: () => import("@/view/system/register"),
  },
  {
    path: "/system/privacy",
    name: "systemPrivacy",
    component: () => import("@/view/system/privacy"),
  },
  {
    path: "/system/param",
    name: "systemParam",
    component: () => import("@/view/system/param"),
  },
  {
    path: "/system/upload",
    name: "systemUpload",
    component: () => import("@/view/system/upload"),
  },
  {
    path: "/system/log",
    name: "systemlog",
    component: () => import("@/view/system/log"),
  },
  {
    path: "/system/service",
    name: "systemService",
    component: () => import("@/view/system/service"),
  },
  {
    path: "/system/agreement",
    name: "systemAgreement",
    component: () => import("@/view/system/agreement"),
  },
];
export default system;
